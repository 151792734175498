
import { Component, Vue } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import { Toy as ToyModel } from '@/models/toy'
import UserAvatar from '@/components/UserAvatar.vue'
import { ApplicationUser } from '@/models/application-user'
import { namespace } from 'vuex-class'
import Icon from '@/components/Icon.vue'
import ChatLink from '@/components/ChatLink.vue'
import CartService from '@/services/CartService'
import { SwapInOrder } from '@/models/swap'
import BigOrangeButton from '@/components/BigOrangeButton.vue'
import { ERROR_CODE } from '@/models/error-map'
import DateUtilities from '@/utilities/DateUtilities'
import { TOY_STATUS_NAMES } from '@/models/id-maps'
import ProductPhotoGallery from '@/components/ProductPhotoGallery.vue'

const Auth = namespace('Auth')
const Chat = namespace('Chat')

@Component({
  components: { ProductPhotoGallery, BigOrangeButton, UserAvatar, Icon, ChatLink },
  metaInfo (): any {
    return {
      title: this.toy.name + ' - Leluelu',
      meta: [
        { name: 'description', content: this.toy.description },
        { name: 'keywords', content: this.toy.name },
        { property: 'og:title', content: this.toy.name + ' - Leluelu' },
        { property: 'og:site_name', content: 'Leluelu' }
      ]
    }
  }
})
export default class Toy extends Vue {
  @Auth.State('user')
  private currentUser!: ApplicationUser;

  @Chat.Action
  private clearMessages: () => void;

  @Chat.State
  private chatActive;

  private message = '';
  private successful = false;
  private addDisabled = false;

  private toy: ToyModel = {
    location: '',
    conditionId: 0,
    description: '',
    id: 0,
    name: '',
    toyStatusId: 0,
    toyPhotos: null,
    applicationUser: {
      userStatusId: 0,
      firstName: '',
      lastName: ''
    },
    applicationUserId: 0
  }

  mounted (): void {
    this.getToy()
  }

  private addToCart (toy: ToyModel): void {
    const swap: SwapInOrder = {
      fromApplicationUserId: toy.applicationUserId,
      toApplicationUserId: this.currentUser.id,
      swapStatusId: 1,
      toyId: toy.id,
      toy: toy
    }
    CartService.addToCart(toy, swap)
    window.location.reload()
  }

  private getToy (): void {
    if (this.$route.params.id != null) {
      UserService.getToy(this.$route.params.id).then(
        (response) => {
          this.toy = response.data
        },
        (error) => {
          console.error('something went wrong. Received error ' + error)
          this.message = ERROR_CODE[error.response.data.message]
        }
      )
    }
  }

  private canShowRequestButton (toy: ToyModel): boolean {
    return this.currentUser != null && this.currentUser.id !== toy.applicationUserId && toy.toyStatusId === 1
  }

  private canShowEditButtons (toy: ToyModel) {
    return this.currentUser != null && this.currentUser.id === toy.applicationUserId
  }

  private getUserInfo (toy) {
    return {
      pictureReference: toy.applicationUser.profilePictureReference,
      firstName: toy.applicationUser.firstName,
      lastName: toy.applicationUser.lastName
    }
  }

  private inactivateToy (): void {
    UserService.inactivateToy(this.toy.id.toString()).then(() => {
      this.successful = true
      this.message = 'deaktiveeritud'
      this.toy.toyStatusId = 2
    }).catch((error) => {
      this.message = ERROR_CODE[error.response.data.message]
    })
  }

  private activateToy (): void {
    UserService.activateToy(this.toy.id.toString()).then(() => {
      this.successful = true
      this.message = 'aktiveeritud'
      this.toy.toyStatusId = 1
    }).catch((error) => {
      this.message = ERROR_CODE[error.response.data.message]
    })
  }

  private canDeactivate (): boolean {
    return this.toy.toyStatusId === 1
  }

  private canActivate (): boolean {
    return this.toy.toyStatusId === 2
  }

  private canEdit (toyStatusId: number): boolean {
    return toyStatusId === 1 || toyStatusId === 2
  }

  private canDelete (): boolean {
    return this.canShowEditButtons(this.toy) && this.canEdit(this.toy.toyStatusId)
  }

  private getFormattedDate (timestamp) {
    return DateUtilities.getDateStringFromString(timestamp)
  }

  private getToyStatus (toyStatusId) {
    return TOY_STATUS_NAMES[toyStatusId]
  }

  private showConfirmationModal (): void {
    // @ts-ignore
    this.$refs['confirmation-modal'].show()
  }

  private hideConfirmationModal (): void {
    // @ts-ignore
    this.$refs['confirmation-modal'].hide()
  }

  toggleToyDeleted (): void {
    UserService.toggleToyDeleted(this.toy.id).then(() => {
      this.$router.push({
        path: '/profile/' + this.currentUser.id,
        query: {
          toastMessage: `Mänguasi '${this.toy.name}' edukalt kustutatud`,
          toastVariant: 'success'
        }
      })
    })
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import UserService from '@/services/UserService'
import { ApplicationUser, PublicUserInfo } from '@/models/application-user'
import PhotoUtilities from '@/utilities/PhotoUtilities'
import Icon from '@/components/Icon.vue'
import ChatLink from '@/components/ChatLink.vue'
import ToyGrid from '@/components/ToyGrid.vue'
import { Toy } from '@/models/toy'
import DateUtilities from '@/utilities/DateUtilities'
import BasicService from '@/services/BasicService'
import { ToySearchRequest } from '@/models/data'
import { ERROR_CODE } from '@/models/error-map'

const AppInfo = namespace('AppInfo')
const Auth = namespace('Auth')

@Component({
  components: { ChatLink, Icon, ToyGrid }
})
export default class PublicProfile extends Vue {
  @AppInfo.Action private rememberPage: (data: {type: string, pageNr: number}) => void;
  @AppInfo.Getter private currentPageProfileActive: number;
  @AppInfo.Getter private currentPageProfileInactive: number;
  @Auth.State('user') private currentUser!: ApplicationUser;
  private activeToys: Toy[] = [];
  private inactiveToys: Toy[] = [];
  private pageSize = 24;

  private isBusy = true;

  private userInfo: PublicUserInfo = {
    id: undefined,
    accountCreated: undefined,
    email: undefined,
    facebook: undefined
  }

  private showProfile = false;
  private errorMessage = '';

  mounted (): void {
    this.fetchUser()
    this.getActiveToysOfUser(this.currentPageProfileActive)
    this.getInactiveToysOfUser(this.currentPageProfileInactive)

    const toastMessage = this.$route.query.toastMessage
    const toastVariant = this.$route.query.toastVariant

    if (toastMessage) {
      this.$bvToast.toast(toastMessage as string, {
        variant: toastVariant as string || 'info',
        autoHideDelay: 5000
      })

      this.$router.replace({ query: null })
    }
  }

  private fetchUser (): void {
    const userId = this.$route.params.id
    if (userId != null) {
      UserService.getUser(userId).then(
        (response) => {
          this.userInfo = response.data
          this.showProfile = true
        },
        (error) => {
          this.showProfile = false
          this.errorMessage = ERROR_CODE[error.response.data.message]
        })
    }
  }

  private getActiveToysOfUser (page: number): void {
    const request: ToySearchRequest = {
      userId: Number(this.$route.params.id),
      toyStatusIds: [1],
      pageNumber: page,
      pageSize: this.pageSize
    }
    BasicService.searchToys(request).then(
      (response) => {
        this.activeToys = response.data
        this.isBusy = false
      },
      (error) => {
        this.activeToys =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  private getInactiveToysOfUser (page: number): void {
    const request: ToySearchRequest = {
      userId: Number(this.$route.params.id),
      toyStatusIds: [2, 3],
      pageNumber: page,
      pageSize: this.pageSize
    }
    BasicService.searchToys(request).then(
      (response) => {
        this.inactiveToys = response.data
        this.isBusy = false
      },
      (error) => {
        this.inactiveToys =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  private parseDateString (date: string): string {
    return DateUtilities.getDateStringFromString(date)
  }

  private storePageNumberActive (page: number): void {
    this.rememberPage({ type: 'profile_active', pageNr: page })
  }

  private storePageNumberInactive (page: number): void {
    this.rememberPage({ type: 'profile_inactive', pageNr: page })
  }

  get isLoggedInVisitor (): boolean {
    const userId = this.$route.params.id
    return this.currentUser && this.currentUser.id !== parseInt(userId)
  }

  get isLoggedInProfileUser (): boolean {
    const userId = this.$route.params.id
    return this.currentUser && this.currentUser.id === parseInt(userId)
  }

  get profilePicture (): string {
    return PhotoUtilities.getProfilePictureUrl(this.userInfo.profilePictureReference)
  }
}
